import { FaUserSecret, FaMoneyBillAlt } from 'react-icons/fa';
import { MdAccountBalanceWallet } from 'react-icons/md';

export const featuresArr1 = [
	'Available 24 × 7',
	'Serving Across India',
	'Five Star Google Ratings',
	'All Compliance at One Place',
	'Best Consultants',
];
export const featuresArr2 = [
	'No Advance Payment',
	'Expertise of CA',
	'Timely Completion',
	'Assured Work',
	'Fast and Quality Work',
];
export const TeamArr = [
	{
		text: 'Chartered Accountants (CA)',
		color1: '#d65d6a',
		color2: '#f0939c',
		icon: <MdAccountBalanceWallet />,
	},
	{
		text: 'Company Secretary (CS)',
		color1: '#39a7b0',
		color2: '#a4e2e9',
		icon: <FaUserSecret />,
	},
	{
		text: 'Property Evaluators',
		color1: '#f1a25e',
		color2: '#fdc596',
		icon: <FaMoneyBillAlt />,
	},
];

export const ITRDocDataA = [
	'Adhar Card of Applicant',
	'PAN Card of Applicant',
	'Form 16/16A',
	'Form 26AS',
];
export const ITRDocDataB = [
	'Bank Statement',
	'Investment nameuments',
	'Rent Agreement',
	'Medical Bills',
];

export const NetworthDataA = [
	{
		name: 'Bunglow / Plot',
	},
	{
		name: 'Appartment / Flats for Residence',
	},
	{
		name: 'Agriculture Land/ other Land',
	},
	{
		name: 'Office/ Commercial Spaces etc.',
	},
	{
		name: 'Attached Furniture etc.',
	},
	{
		name: 'Other Immovable Property/Assets',
	},
];

export const NetworthDataB = [
	{
		name: "Bank Balance / FD's / Mutual Fund / Shares etc.",
	},
	{
		name: 'Vehicle (Car, Bike, Bus, Plane, etc.)',
	},
	{
		name: 'Gold Ornaments, Diamonds, Metal etc.',
	},
	{
		name: 'Book Value of Sundry Debtors, Stock, Assets etc.',
	},
	{
		name: 'Realization Value of Life Insurance, Investments etc.',
	},
	{
		name: 'Other Monetory Assets',
	},
];

export const PropertyValPurposeA = [
	{
		name: 'Immigration/VISA Purpose',
	},
	{
		name: 'Internal Transfer or Selling Purpose',
	},
	{
		name: 'Capital Gain - Income Tax Purpose',
	},
];

export const PropertyValPurposeB = [
	{
		name: 'Wealth Tax purpsoe',
	},
	{
		name: 'Valuation for IPO/REIT etc.',
	},
	{
		name: 'Assessment of Property/ Other Purpose',
	},
];

export const PropertyDocDataA = [
	{
		name: 'Adhar Card of Applicant',
	},
	{
		name: 'PAN Card of Applicant',
	},
	{
		name: 'Ownership Proof (Tax Bill or Electricity Bill)',
	},
];
export const PropertyDocDataB = [
	{
		name: 'Index Copy/ Purchase Agreement',
	},
	{
		name: 'List of Furniture and Attached assets',
	},
	{
		name: 'Email and Mobile Number',
	},
];

export const PropertyDocumentsDataArr = [
	{
		title: 'Index Copy:',
		text: ' This document includes information about the area, address, and owner of the property. Generally issued by the registrar upon completion of the sale agreement transfer.',
	},
	{
		title: 'Land Record:',
		text: ' For agricultural land, either the Record of Rights (7-12 Survey) or Land Patta Record is required.',
	},
	{
		title: 'Title Deed:',
		text: " A crucial document proving ownership, containing essential information such as the owner's name, property description, and any encumbrances.",
	},
	{
		title: 'Sale Deed:',
		text: ' This legal document records the transfer of property ownership and includes details like the purchase price, transaction date, and parties involved.',
	},
	{
		title: 'Occupancy Certificate:',
		text: " Necessary for newly constructed or renovated properties, certifying the property's safety and compliance with local building codes.",
	},
	{
		title: 'Any Other Proof:',
		text: ' Additional documents justifying ownership, possession, and property area.',
	},
];
export const PropertySummaryDataArr = [
	{
		title: 'Agriculture Land/ Farms:',
		text: 'Documents may include 8-A Khata Record, 7-12 Survey Record, Land Patta Records, or a Purchase Agreement.',
	},
	{
		title: 'Urban Land/ Plots/ Space:',
		text: 'Documents like Index Copy, Property Tax Receipt, and Sale Agreement are needed.',
	},
	{
		title: 'House/ Home/ Tenement/ Row house/ Flat/ Apartment/ Bungalow:',
		text: 'Documents such as Sale Agreement, Gift Deed/Will/Power of Attorney, or an Allotment letter from the builder may be required.',
	},
	{
		title:
			'Office/ Commercial Building/ Shop/ Showroom/ Corporate House/ Factory/ Go down/ Warehouse:',
		text: 'Documents like Registrar Index Copy, Property Tax Acknowledgement, Electricity Bill, Sale Agreement, Title Deed, Allotment Letter, or Building Usage Permission Letter may be needed for commercial and industrial premises.',
	},
];

export const CAValuationReqArrA = [
	'Student VISA Applicant for Canada, USA, Australia etc',
	'Person/Family applying for Visitor VISA',
	'F1 Visa Applicants',
];
export const CAValuationReqArrB = [
	'Immigration Application for Permanent Residents',
	'Tourist VISA',
	'Business VISA / Work Permits',
];
export const CAValuationReportArrA = [
	'All Liquid Assets like Savings, Fixed Deposits, Funds etc',
	'Holding of Shares, Investment in Mutual Fund etc',
	'Provident Fund, Insurance, GIC Deposit etc',
];
export const CAValuationReportArrB = [
	'Gold and Other Assets',
	'Immovable Property like Home, Office etc',
	'All assets having worthiness in rupee term',
];
export const CAValuationCertiArrA = [
	'Must be From Practicing CA',
	'License Number Should be Stated FRN and MRN',
	'Letterhead of CA required',
];
export const CAValuationCertiArrB = [
	'UDIN for Certificate must be generated at the time of sign',
	'Signature and Stamp of CA',
	'Purpose of Valuation Certificate',
];
